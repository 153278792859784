import { vikingApiClient } from "@/http-client"
import { SessionSearchFilter, Session, SessionSearchCriteria } from "@/types"

// Note: Deprecate this method in favor and rename `_fetchSessions` to `fetchSessions` once https://elocal.atlassian.net/browse/PEV-8406 is completed
export async function fetchSessions(searchCriteria: SessionSearchCriteria): Promise<Session[]> {
  let requestUrl: string | null = null

  // Map search criteria to API search params
  switch (searchCriteria.filterType) {
    case SessionSearchFilter.SESSION_ID:
      // searchParams.sessionId = searchCriteria.sessionId!
      requestUrl = `session-management/session/${searchCriteria.sessionId!}`
      break

    case SessionSearchFilter.SESSION_ALIAS:
      // searchParams.sessionAlias = searchCriteria.sessionAlias!
      requestUrl = `session-management/session/alias/${searchCriteria.sessionAlias!}`
      break
  }

  const session = await vikingApiClient.get<Session>(requestUrl)
  return Array.of(session)
}

// Note: Switch to this method once https://elocal.atlassian.net/browse/PEV-8406 is completed
export async function _fetchSessions(searchCriteria: SessionSearchCriteria): Promise<Session[]> {
  const searchParams: Record<string, string | number | boolean> = {}

  // Map search criteria to API search params
  switch (searchCriteria.filterType) {
    case SessionSearchFilter.SESSION_ID:
      searchParams.sessionId = searchCriteria.sessionId!
      break

    case SessionSearchFilter.SESSION_ALIAS:
      searchParams.sessionAlias = searchCriteria.sessionAlias!
      break
  }

  const listings = await vikingApiClient.get<Session[]>(`listing-processor/sessions`, { searchParams })
  return listings
}

export async function fetchSession(sessionId: number | string): Promise<Session> {
  const session = await vikingApiClient.get<Session>(`session-management/session/${sessionId}`)
  return session
}
