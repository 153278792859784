import { FC } from "react"
import { Form } from "react-final-form"
import { useTranslation } from "react-i18next"
import { FormApi, SubmissionErrors } from "final-form"
import { Box, Button, Grid, Stack } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import SearchIcon from "@mui/icons-material/Search"

import { Dropdown, DropdownOption, TextField } from "@/components/forms/inputs"
import { SessionSearchFilter, SessionSearchCriteria } from "@/types"

const dropdownOptions: DropdownOption[] = [
  {
    label: "SESSIONS_PAGE.SESSION_ID",
    value: SessionSearchFilter.SESSION_ID
  },
  {
    label: "SESSIONS_PAGE.SESSION_ALIAS",
    value: SessionSearchFilter.SESSION_ALIAS
  }
]

const buttonStyles = {
  height: "55px"
}

const validate = (values: SessionSearchCriteria) => {
  const { filterType, sessionId, sessionAlias } = values

  const errors: Partial<Record<keyof SessionSearchCriteria, string | undefined>> = {}

  // Note: Delete lines 33 to 36 once https://elocal.atlassian.net/browse/PEV-8406 is completed to allow search with no criteria
  if (!filterType) {
    errors.filterType = "SESSIONS_PAGE.FILTER_TYPE_REQUIRED"
  }

  switch (filterType) {
    case SessionSearchFilter.SESSION_ID:
      if (!sessionId) {
        errors.sessionId = "SESSIONS_PAGE.SESSION_ID_REQUIRED"
      }
      break

    case SessionSearchFilter.SESSION_ALIAS:
      if (!sessionAlias) {
        errors.sessionAlias = "SESSIONS_PAGE.SESSION_ALIAS_REQUIRED"
      }
      break
  }

  return errors
}

interface ListingSearchFormProps {
  initialValues?: Partial<SessionSearchCriteria>
  isPending: boolean
  onSubmit: (
    values: SessionSearchCriteria,
    form: FormApi<SessionSearchCriteria, Partial<SessionSearchCriteria>>,
    callback?: (errors?: SubmissionErrors) => void
  ) => SubmissionErrors | Promise<SubmissionErrors> | void
}

export const SessionSearchForm: FC<ListingSearchFormProps> = ({ initialValues, isPending, onSubmit }) => {
  const { t } = useTranslation()

  const inputVariant = "outlined"

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Form<SessionSearchCriteria>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form, values, valid }) => {
          const { filterType } = values as SessionSearchCriteria

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <Dropdown name="filterType" label="Filter type" options={dropdownOptions} withEmptyOption />
                </Grid>

                <Grid item xs={12} md={6}>
                  {filterType === SessionSearchFilter.SESSION_ID && (
                    <TextField name="sessionId" label="SESSIONS_PAGE.SESSION_ID" variant={inputVariant} />
                  )}

                  {filterType === SessionSearchFilter.SESSION_ALIAS && (
                    <TextField name="sessionAlias" label="SESSIONS_PAGE.SESSION_ALIAS" variant={inputVariant} />
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    display: "flex",
                    alignSelf: "flex-start",
                    justifyContent: "space-around"
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Button variant="outlined" color="primary" onClick={() => form.reset()} endIcon={<RestartAltIcon />} sx={buttonStyles}>
                      {t("COMMON.RESET")}
                    </Button>

                    <LoadingButton
                      variant="contained"
                      color="primary"
                      disabled={!valid}
                      loading={isPending}
                      onClick={() => form.submit()}
                      type="submit"
                      endIcon={<SearchIcon />}
                      sx={buttonStyles}
                    >
                      {t("COMMON.SEARCH")}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </Box>
  )
}
